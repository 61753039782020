import React, { useCallback } from "react"
import { Provider } from "react-redux"
import {
  CartProvider,
  CustomerProvider,
  SearchProvider,
  AppProvider,
  WindowProvider,
  WindowCtx,
  useActions,
  AppCtx,
} from "@components/contexted"

import store from "@stores"
import { getShippingMethods } from "./app.logic"
import Loader from "./InitLoading"

const onAppInit = (dispatch) => {}

const onInitSearchData = (appLoaded) => (dispatch) => {
  appLoaded()
}

const onInitCartData = (dispatch) => {
  console.log("initCart")
  getShippingMethods()
    .then((data) => {
      console.log("intCartData", data)
      dispatch({
        type: "setShippings",
        payload: data,
      })
    })
    .catch((err) => {
      console.log("initCartError")
      dispatch({
        type: "onRequestError",
        payload: err,
      })
    })
}

const OuterProviders = ({ children }) => {
  return (
    <WindowProvider>
      <AppProvider onLoad={onAppInit}>{children}</AppProvider>
    </WindowProvider>
  )
}

const InnerProviders = ({ children }) => {
  const { loaded } = useActions(AppCtx, "loaded")
  const onLoadSearchData = useCallback(onInitSearchData(loaded))

  return (
    <Provider store={store}>
      <CustomerProvider onLoad={onLoadSearchData}>
        <CartProvider onLoad={onInitCartData}>
          <Loader />
          {children}
        </CartProvider>
      </CustomerProvider>
    </Provider>
  )
}

export default ({ element }) => {
  return (
    <OuterProviders>
      <InnerProviders>{element}</InnerProviders>
    </OuterProviders>
  )
}

export { WindowCtx }
